@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400&display=swap');

h1 {
  font-size: calc(30px + 3vmin);;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  background-color: #fac2fe;
  background-image: linear-gradient(135deg, #fbc3ff 0%, #f6fbbb 52%, #b3ffd4 90%);
  /*
  background: linear-gradient(135deg, #FFCCFF 0%, #FFFFCC 50%, #CCFFFF 100%) fixed;
  background-color: #FFFFFF;
  */
  justify-content: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-home {
  min-height: 100vh;
  min-width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-link {
  font-family: 'Noto Sans KR', sans-serif;
  background: linear-gradient(135deg, #FFCCFF 0%, #FFFFCC 50%, #CCFFFF 100%) fixed;
  background-color: #FFFFFF;
  border-radius: 99999px;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;
  color: #000000;
  font-size: calc(10px + 1vmin);
  font-weight: 400;
  line-height: inherit;
  padding: calc(0.667em + 2px) calc(1.333em + 2px);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.App-link:hover {
  background: linear-gradient(135deg, #CCFFFF 0%, #FFFFCC 50%, #FFCCFF 100%) fixed;
  color: #000;
  text-decoration: none;
}
